<template>
  <div>
    <div>
      <div v-show="isShow == 0">
        <div class="breadcrumb">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item>
            <el-breadcrumb-item>出库单</el-breadcrumb-item>
          </el-breadcrumb>
          <el-button type="success" style="height: 36px" @click="reload">
            <i style="font-size: 18px" class="el-icon-refresh-right"></i>
          </el-button>
        </div>

        <div style="
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 20px 20px 0;
        white-space: nowrap;
      ">
          <el-row>
            <el-col :span="8">
              <span>创建日期：</span>
              <el-date-picker v-model="t1" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-col>
            <el-col :span="8">
              <span>送货日期：</span>
              <el-date-picker v-model="t2" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-col>
          </el-row>

          <el-row style="margin-top: 10px; display: flex; flex-wrap: wrap">
            <el-col :span="9">
              <span>订单状态：</span>
              <el-select v-model="query.order_status" clearable filterable placeholder="订单状态">
                <el-option v-for='(item, index) in ["未确认", "已派单", "已送达", "已完成"]' :key="index" :label="item" :value="item">
                </el-option>
              </el-select>
              <!-- <el-select v-model="option2" clearable filterable placeholder="配送状态" style="margin: 0 5px">
                <el-option v-for="item in option2es" :key="item" :label="item" :value="item">
                </el-option>
                <el-select v-model="option3" clearable filterable placeholder="订单状态">
                  <el-option v-for="item in option1es" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-select> -->
              <el-select v-model="query.station" clearable filterable placeholder="配送站点">
                <el-option v-for="item in stationOptions" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="7">
              <el-input v-model="query.search" placeholder="输入要查询的内容" style="width: 70%"></el-input>
              <el-button type="success" size="mini" style="margin-left: 5px" @click="handleQuery()">
                <div style="display: flex; align-items: center; font-size: 14px">
                  <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                  <span style="margin-left: 3px">查询</span>
                </div>
              </el-button>
            </el-col>
          </el-row>
        </div>

        <div style="
        margin: 20px;
        padding: 5px 10px;
        border: 1px solid #eeeeee;
        background-color: #f5fafe;
        display: flex;
        align-items: center;
      ">
          <el-button type="primary" size="mini" @click="dialogFormVisible1 = true">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
              <span style="margin-left: 5px">导出送货单</span>
            </div>
          </el-button>
        </div>

        <div style="padding: 20px">
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border style="width: 100%"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column sortable label="创建日期">
              <template slot-scope="scope">{{ scope.row.create_time }}</template>
            </el-table-column>
            <el-table-column prop="order_number" sortable label="订单号">
            </el-table-column>
            <el-table-column prop="consignee" label="收货人"> </el-table-column>
            <el-table-column prop="phone" label="联系人"> </el-table-column>
            <el-table-column prop="region" label="地区"> </el-table-column>
            <el-table-column prop="location" label="地址"> </el-table-column>
            <el-table-column prop="station" label="配送站"> </el-table-column>
            <el-table-column prop="delivery" label="送货员"> </el-table-column>
            <el-table-column prop="plan_time" sortable label="预约时间">
            </el-table-column>
            <!-- <el-table-column prop="plan_time" sortable label="预估送达时间">
        </el-table-column> -->
            <el-table-column prop="service_time" sortable label="送达时间">
            </el-table-column>
            <el-table-column prop="number" sortable label="送货数量">
            </el-table-column>
            <el-table-column prop="order_status" label="订单状态">
            </el-table-column>
            <!-- <el-table-column prop="deliveryStatus" label="送货状态">
        </el-table-column> -->
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button @click="handleEdit(scope.$index, scope.row)" type="success" size="mini">
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- <div class="tableBottom">
            <span v-if="isResult" style="">显示第&nbsp;1&nbsp;至&nbsp;{{
              tableFinshNum
            }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>
            <span v-if="!isResult" style="">显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
              tableFinshNum
            }}&nbsp;项</span>
            <div style="display: flex; justify-content: flex-end; align-items: center">
              <el-button>首页</el-button>
              <el-button>上页</el-button>
              <el-pagination background layout=" pager,slot" :total="1"></el-pagination>
              <el-button>下页</el-button>
              <el-button>末页</el-button>
            </div>
          </div> -->
          <pagination :handleQuery="handleQuery" :currentPage="query.currentPage" :currentLength="query.currentLength"
            :total="total">
          </pagination>
        </div>
      </div>
    </div>
    <Detail ref="detail" v-show="isShow == 1" :onClose="onClose"></Detail>
  </div>
</template>
<script>
import pagination from "@/components/pagination/index.vue";
import { outbound } from "@/api/salesManagement/manualDeclaration.js";
import { station } from "@/api/salesManagement/salesOrder.js";
import { getDate } from "@/until/utilse.js";
import Detail from "../label3_sellOrder/childrens/details.vue"

export default {
  async created() {
    this.handleQuery();
    let res = await station();
    this.stationOptions = res.data.station.map(el => el.shop_name)
    console.log(this.stationOptions)

  },
  components: { Detail, pagination },
  data() {
    return {
      isShow: 0,
      tableData: [],
      t1: "",
      t2: "",
      total: 0,
      query: {
        "commercial_id": "",
        "firstTime": "",
        "lastTime": "",
        "f_Time": "",
        "l_Time": "",
        "site_name": "",
        "order_status": "",
        "station": "",
        "search": "",
        // "delivery": "",
        // "ordering_method": "",
        "currentPage": 1,
        "currentLength": 10
      },
      stationOptions: [],
      creatTime: {},
      deliveryTime: "",

      option1: "",
      option1es: "",
      option2: "",
      option2es: "",
      option3: "",
      option3es: "",
      QueryContent: "",

      multipleSelection: [],
      isResult: false,
      tableFinshNum: 0,

    };
  },
  mounted() {
    if (this.tableData.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tableData.length;
    }
  },
  computed: {},
  methods: {
    onClose() {
      this.isShow = 0;
    },
    reload() {
      location.reload();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 点击详情按钮对应的操作
    async handleEdit(index, row) {
      // console.log(index, id)
      let { id } = row
      this.$refs.detail.tableData = row;
      this.isShow = 1;
    },
    // 处理查询接口
    async handleQuery(page = 1) {
      if (this.t1) {
        var [firstTime, lastTime] = this.t1;
        firstTime = getDate(firstTime);
        lastTime = getDate(lastTime);
      }
      if (this.t2) {
        var [f_Time, l_Time] = this.t2;
        f_Time = getDate(f_Time);
        l_Time = getDate(l_Time);
      }
      this.query = {
        ...this.query, firstTime, lastTime, f_Time, l_Time
      }
      console.log(this.query)
      this.query.currentPage = page;
      let res = await outbound(this.query);
      this.total = res.data.count
      this.tableData = res.data.data
      // console.log(data)
    }
  },
};
</script>
<style scoped lang="less">
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/deep/ .el-col {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 14px;
  margin: 0 5px;
}

/deep/ .cell {
  padding: 0 !important;
  white-space: nowrap !important;
}
</style>
